<template>
  <el-dialog
    title="订单详细"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <div class="order">
      <div class="leftOrder">
        <div class="headTil">订单基本信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">订单编号:</div>
            <div class="rightValue">{{ dataForm.idText }}</div>
          </div>
          <div class="conList">
            <div class="leftName">订单类型:</div>
            <div class="rightValue">{{ dataForm.typeText }}</div>
          </div>
          <div class="conList">
            <div class="leftName">订单状态:</div>
            <div class="rightValue">{{ dataForm.stateText }}</div>
          </div>
          <div class="conList">
            <div class="leftName">取餐码:</div>
            <div class="rightValue">{{ dataForm.pickupCode }}</div>
          </div>
          <div class="conList">
            <div class="leftName">取餐日期:</div>
            <div class="rightValue">{{ dataForm.pickupDate }}</div>
          </div>
          <div class="conList">
            <div class="leftName">下单时间:</div>
            <div class="rightValue">{{ dataForm.paidAt }}</div>
          </div>
          <div class="conList">
            <div class="leftName">完成时间:</div>
            <div class="rightValue">{{ dataForm.completionTime }}</div>
          </div>
        </div>

        <div class="headTil">取货人信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">用户编号:</div>
            <div class="rightValue">{{ dataForm.uuid }}</div>
          </div>
          <div class="conList">
            <div class="leftName">注册手机号码:</div>
            <div class="rightValue">{{ dataForm.registerMobile }}</div>
          </div>
          <div class="conList">
            <div class="leftName">姓名:</div>
            <div class="rightValue">{{ dataForm.consignee }}</div>
          </div>
          <div class="conList">
            <div class="leftName">手机号:</div>
            <div class="rightValue">{{ dataForm.mobile }}</div>
          </div>
        </div>
      </div>

      <div class="middleOrder">
        <div class="headTil">配餐公司信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">名称:</div>
            <div class="rightValue">{{ dataForm.orgName }}</div>
          </div>
        </div>

        <div class="headTil">时段信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">名称:</div>
            <div class="rightValue">{{ dataForm.periodFullName }}</div>
          </div>
          <div class="conList">
            <div class="leftName">起始时间:</div>
            <div class="rightValue">{{ dataForm.periodStartTime }}</div>
          </div>
          <div class="conList">
            <div class="leftName">结束时间:</div>
            <div class="rightValue">{{ dataForm.periodEndTime }}</div>
          </div>
        </div>

        <div class="headTil">取餐方式信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">取餐方式:</div>
            <div class="rightValue">{{ dataForm.pickupWayText }}</div>
          </div>
          <div class="conList">
            <div class="leftName">名称:</div>
            <div class="rightValue">{{ dataForm.freezerName }}</div>
          </div>
          <div class="conList">
            <div class="leftName">编号:</div>
            <div class="rightValue">{{ dataForm.freezerSn }}</div>
          </div>
          <div class="conList">
            <div class="leftName">地址:</div>
            <div class="rightValue">{{ dataForm.freezerAddress }}</div>
          </div>
        </div>
      </div>

      <div class="rightOrder">
        <div class="headTil">订单金额信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">支付方式:</div>
            <div class="rightValue">{{ dataForm.payText }}</div>
          </div>
          <div class="conList">
            <div class="leftName">订单总金额:</div>
            <div class="rightValue">
              {{ dataForm.orderPrice }}
            </div>
          </div>
          <div class="conList">
            <div class="leftName">商品总金额:</div>
            <div class="rightValue">{{ dataForm.totalPrice }}</div>
          </div>
          <div class="conList">
            <div class="leftName">优惠总金额:</div>
            <div class="rightValue">{{ dataForm.sumSubPrice }}</div>
          </div>
          <div class="conList">
            <div class="leftName">余额支付金额:</div>
            <div class="rightValue">
              {{ dataForm.balance }}
            </div>
          </div>
          <div class="conList">
            <div class="leftName">微信支付金额:</div>
            <div class="rightValue">{{ dataForm.wechatPrice }}</div>
          </div>
          <div class="conList">
            <div class="leftName">补助支付总金额:</div>
            <div class="rightValue">{{ dataForm.subsidyPrice }}</div>
          </div>
          <div class="conList">
            <div class="leftName">公司补助账户支付:</div>
            <div class="rightValue">{{ dataForm.daySubsidy }}</div>
          </div>
          <div class="conList">
            <div class="leftName">员工补助账户支付:</div>
            <div class="rightValue">{{ dataForm.monthSubsidy }}</div>
          </div>
          <div class="conList">
            <div class="leftName">优惠券金额:</div>
            <div class="rightValue">{{ dataForm.sumSubPrice }}</div>
          </div>
        </div>

        <div class="headTil">订单备注信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">备注:</div>
            <div class="rightValue">{{ dataForm.remark }}</div>
          </div>
        </div>
      </div>

      <div class="fillOrder">
        <div class="headTil">商品</div>
        <div class="containerPart">
          <div class="goodsBlock" v-for="(good, index) in goods" :key="index">
            <div class="leftImage">
              <el-image
                style="width: 100px; height: 100px"
                :src="good.imageUrl"
                :fit="good.imageUrl"
              ></el-image>
            </div>
            <div class="rightCon">
              <div class="nameList">
                名称:{{
                  good.name +
                  (good.goodsId !== null ? '' : ':' + good.packageCombo)
                }}
              </div>
              <div class="nameList">编号:{{ good.sn }}</div>
              <div class="nameList">单价:{{ good.price }}</div>
              <div class="nameList">数量:{{ good.quantity }}</div>
              <div class="nameList">金额:{{ good.price * good.quantity }}</div>
              <div class="nameList">仓位码:{{ good.spaceCode }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        userId: '',
        nickname: '',
        consignee: '',
        mobile: '',
        balance: '',
        wechatPrice: '',
        periodFullName: '',
        subsidyPrice: '',
        daySubsidy: '',
        monthSubsidy: '',
        orderPrice: '',
        periodStartTime: '',
        periodEndTime: '',
        freezerSn: '',
        freezerName: '',
        freezerAddress: '',
        orgName: '',
        idText: '',
        pickupDate: '',
        typeText: '',
        stateText: '',
        payText: '',
        pickupCode: '',
        totalPrice: '',
        actualPrice: '',
        createdAt: '',
        paidAt: '',
        cutOffTime: '',
        remark: '',
        completionTime: '',
        pickupWayText: '',
        sumSubPrice: '',
      },
      goods: [],
    };
  },
  methods: {
    init(data) {
      this.dataForm = data;
      this.visible = true;
      if (this.dataForm.id) {
        this.$http({
          url: `/tc/orderDetail/tree/order?orderIds=${this.dataForm.id}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.status === 0) {
            this.goods = data.data.items;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.order {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .leftOrder {
    width: 40%;
    padding: 10px;
    position: relative;
    .headTil {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 40px;
      border-bottom: 2px #efefef solid;
      width: 100%;
      height: 40px;
    }
    .containerPart {
      position: relative;
      padding: 10px 0;
      .conList {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        //justify-content: center;
        .leftName {
          width: 100px;
          font-size: 14px;
          //color: #000;
          font-weight: 500;
          padding: 0 10px 0 0;
          //text-align: right;
          line-height: 1.8;
        }
        .rightValue {
          flex: 1;
          font-size: 14px;
          color: #666;
          line-height: 1.8;
          img {
            max-width: 100px;
          }
        }
      }
    }
  }
  .middleOrder {
    flex: 1;
    padding: 10px;
    position: relative;
    .headTil {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 40px;
      border-bottom: 2px #efefef solid;
      width: 100%;
      height: 40px;
    }
    .containerPart {
      position: relative;
      padding: 10px 0;
      .conList {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        //justify-content: center;
        .leftName {
          width: 80px;
          font-size: 14px;
          //color: #000;
          font-weight: 500;
          padding: 0 10px 0 0;
          //text-align: right;
          line-height: 1.8;
        }
        .rightValue {
          flex: 1;
          font-size: 14px;
          color: #666;
          line-height: 1.8;
          img {
            max-width: 100px;
          }
        }
        .inVal {
          display: flex;
          flex-wrap: wrap;
          .thename {
            width: 80px;
            line-height: 36px;
            text-align: center;
          }
        }
      }
    }
  }
  .rightOrder {
    flex: 1;
    padding: 10px;
    position: relative;
    .headTil {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 40px;
      border-bottom: 2px #efefef solid;
      width: 100%;
      height: 40px;
    }
    .containerPart {
      position: relative;
      padding: 10px 0;
      .conList {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        //justify-content: center;
        .leftName {
          width: 150px;
          font-size: 14px;
          //color: #000;
          font-weight: 500;
          padding: 0 10px 0 0;
          //text-align: right;
          line-height: 1.8;
        }
        .rightValue {
          flex: 1;
          font-size: 14px;
          color: #666;
          line-height: 1.8;
          img {
            max-width: 100px;
          }
        }
        .inVal {
          display: flex;
          flex-wrap: wrap;
          .thename {
            width: 80px;
            line-height: 36px;
            text-align: center;
          }
        }
      }
    }
  }
  .fillOrder {
    flex: 0 0 100%;
    padding: 10px;
    position: relative;
    .headTil {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 40px;
      border-bottom: 2px #efefef solid;
      width: 100%;
      height: 40px;
    }
    .containerPart {
      position: relative;
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      .goodsBlock {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        flex: 0 0 50%;
        border-right: 1px #efefef solid;
        border-bottom: 1px #efefef solid;
        .leftImage {
          width: 120px;
          margin: 0 auto;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100px;
          }
        }
        .rightCon {
          flex: 1;
          position: relative;
          overflow: hidden;
          .nameList {
            font-size: 14px;
            color: #666;
            line-height: 1.8;
          }
        }
      }
      .conList {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        //justify-content: center;
        .leftName {
          width: 80px;
          font-size: 14px;
          //color: #000;
          font-weight: 500;
          padding: 0 10px 0 0;
          //text-align: right;
          line-height: 1.8;
        }
        .rightValue {
          flex: 1;
          font-size: 14px;
          color: #666;
          line-height: 1.8;
          img {
            max-width: 100px;
          }
        }
        .inVal {
          display: flex;
          flex-wrap: wrap;
          .thename {
            width: 80px;
            line-height: 36px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
